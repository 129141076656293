import { Box, Stack, Typography } from '@mui/material'
import { ComponentProps, FC } from 'react'
import { RichText } from '../RichText'

type Props = {
  title: string
} & ComponentProps<typeof RichText>

export const ContentsFrame: FC<Props> = (props) => {
  const { title, editorState, theme, namespace } = props

  return (
    <Box>
      <Stack spacing='8px' direction='column'>
        <Typography
          variant='h3'
          component='h1'
          fontWeight='bold'
          fontSize='20px'
          lineHeight='30px'
          textAlign='center'
        >
          {title}
        </Typography>
        <Stack spacing='8px' fontSize={{ xs: '12px', sm: '14px' }} lineHeight='18px'>
          <RichText editorState={editorState} namespace={namespace} theme={theme} />
        </Stack>
      </Stack>
    </Box>
  )
}
