import { Box, Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { convertDateTime } from '../../utils/date'

type Props = {
  started: string
  ended: string
}

export const DateView: FC<Props> = (props) => {
  const { started, ended } = props

  return (
    <Box bgcolor='#fff' border='1px #EF7F43 solid' borderRadius='4px' width='100%'>
      <Box height='32px' bgcolor='#EF7F43' textAlign='center' display='flex' alignItems='center'>
        <Typography fontWeight={600} fontSize='16px' lineHeight='24px' color='#fff' width='100%'>
          利用期間
        </Typography>
      </Box>
      <Stack
        py='8px'
        textAlign='left'
        display='flex'
        justifyContent='center'
        direction={{ sm: 'column', md: 'row' }}
        width='fit-content'
        mx='auto'
      >
        <Typography
          fontSize='14px'
          lineHeight='18px'
          mr='6px'
          whiteSpace='nowrap'
          width='fit-content'
        >
          {convertDateTime(started, 'YYYY/MM/DD (ddd) HH:mm')} 〜
        </Typography>
        <Typography
          fontSize='14px'
          lineHeight='18px'
          mr='6px'
          whiteSpace='nowrap'
          width='fit-content'
        >
          {convertDateTime(ended, 'YYYY/MM/DD (ddd) HH:mm')} まで
        </Typography>
      </Stack>
    </Box>
  )
}
