import { Box, CardProps } from '@mui/material'
import { FC } from 'react'

type Props = CardProps

export const Card: FC<Props> = (props) => {
  return (
    <Box
      {...props}
      overflow='hidden'
      sx={{
        borderRadius: 0,
        p: '16px',
        height: 1,
        boxSizing: 'border-box',
        backgroundColor: '#fff',
      }}
    />
  )
}
