import { Typography } from '@mui/material'
import { FC } from 'react'

export const Footer: FC = () => {
  return (
    <Typography
      variant='body1'
      textAlign='center'
      width='100%'
      fontSize='10px'
      lineHeight='20px'
      translate='no'
    >
      ©︎mediba
    </Typography>
  )
}
