import { Icon } from '@mui/material'
import { FC } from 'react'
import check from './check.svg'

export const CheckIcon: FC = () => {
  return (
    <Icon sx={{ height: '42px', width: '42px', textAlign: 'center' }}>
      <img src={check} style={{ display: 'flex', height: 'inherit', width: 'inherit' }} />
    </Icon>
  )
}
