import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(
  createStyles({
    link: {
      textDecoration: 'none',
      color: 'rgb(33, 111, 219)',
      '&:hover': {
        textDecoration: 'underline',
        cursor: 'pointer',
      },
    },
    root: {
      padding: '0 14px',
    },
  }),
)
