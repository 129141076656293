export const getURL = () => new URL(window.location.href)

export const getURLSearchParam = (url: URL, key: string) => url.searchParams.get(key)

export const isURL = (str: string) => {
  try {
    new URL(str)
    return true
  } catch {
    return false
  }
}
