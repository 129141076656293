import { ClassNameMap } from '@mui/styles'
import { EditorState } from 'lexical'

export type ThemeEditorState = {
  theme: ClassNameMap
  editorState: EditorState
}

export const getThemeEditorState = (json: string) => {
  if (isJson(json)) {
    return JSON.parse(json) as ThemeEditorState
  }
  return null
}

export const getTheme = (json: string) => {
  return getThemeEditorState(json)?.theme ?? {}
}

export const getEditorStateJSON = (json: string) => {
  return JSON.stringify(getThemeEditorState(json)?.editorState)
}

export const isJson = (json: string) => {
  try {
    JSON.parse(json)
    return true
  } catch {
    return false
  }
}
