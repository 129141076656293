import { Box, Stack } from '@mui/material'
import { useQuery } from '@tanstack/react-query'
import axios, { AxiosError } from 'axios'
import { FC } from 'react'
import { config } from '../config'
import { Coupon } from '../types/coupon'
import { getEditorStateJSON, getTheme } from '../utils/richText'
import { getURL, getURLSearchParam } from '../utils/url'
import { AttentionLabel } from './AttentionLablel'
import { Barcode } from './Barcode'
import { Card } from './Card'
import { ContentsFrame } from './ContentsFrame'
import { DateView } from './DateView'
import { Footer } from './Footer'
import { Header } from './Header'
import { ImageSwiper } from './ImageSwiper'
import { RichText } from './RichText'

export const Main: FC = () => {
  const id = getURLSearchParam(getURL(), 'id')
  const pin = getURLSearchParam(getURL(), 'pin')
  const { data, error, isError, isLoading } = useQuery<Coupon, AxiosError>([id], () =>
    axios.get(`${config.api_url}/coupon/${id}?pin=${pin}`).then((res) => res.data),
  )

  if (isLoading) {
    return <></>
  }

  /**
   * 404 => 404 page
   * 403 => maintenance page
   * その他 => 5xx page
   */
  const errorStatus = error?.response?.status
  if (isError) {
    if (errorStatus === 404) {
      window.location.href = `${config.root_url}/404.html`
    } else if (errorStatus === 403) {
      window.location.href = `${config.root_url}/maintenance.html`
    } else {
      window.location.href = `${config.root_url}/5xx.html`
    }
    return <></>
  }

  const richTextTheme = getTheme(data.coupon_text)

  return (
    <Stack maxWidth='768px' mx='auto' minHeight='100vh' spacing='8px'>
      <Stack spacing={data.usage_status === 0 ? '8px' : 0}>
        <Header clientLogo={data.chain_store_logo_url || data.chain_store_name} />
        {data.usage_status !== 0 && <AttentionLabel mode={data.usage_status} />}
        <Card>
          <Box fontSize={{ xs: '13px', sm: '18px' }}>
            該当商品をレジまでお持ちになり、「画面に表示しているバーコード」のスキャンをお申し付けください。
          </Box>
          {/* バーコードが存在しない場合は16pxの余白を設ける */}
          <Box mx='auto' width='fit-content' minHeight='16px'>
            {data.pin && <Barcode value={data.pin}></Barcode>}
          </Box>
          <ImageSwiper images={[...data.img_url_list]} />
          <Box my={2} sx={{ fontSize: '18px', fontWeight: 'bold', lineHeight: '27px' }}>
            <RichText
              editorState={getEditorStateJSON(data.coupon_text)}
              namespace='coupon_text'
              theme={richTextTheme}
            />
          </Box>
          <DateView started={data.started} ended={data.ended} />
        </Card>
      </Stack>
      <Card>
        <ContentsFrame
          title='利用方法'
          editorState={getEditorStateJSON(data.usages)}
          theme={richTextTheme}
          namespace='usages'
        />
      </Card>
      <Card>
        <ContentsFrame
          title='注意事項'
          editorState={getEditorStateJSON(data.notes)}
          theme={richTextTheme}
          namespace='notes'
        />
      </Card>
      <Box width='100%' height='34px' display='flex' pt='8px' mt='auto !important'>
        <Footer />
      </Box>
    </Stack>
  )
}
