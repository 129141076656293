const environment = {
  local: {
    api_url: 'https://feat-uitest.d1cpo440rywqxx.amplifyapp.com/api/web',
    root_url: 'https://dev.coupon.mediba.jp',
  },
  development: {
    api_url: 'https://api.dev.coupon.mediba.jp',
    root_url: 'https://dev.coupon.mediba.jp',
  },
  staging: {
    api_url: 'https://api.stg.coupon.mediba.jp',
    root_url: 'https://stg.coupon.mediba.jp',
  },
  production: {
    api_url: 'https://api.coupon.mediba.jp',
    root_url: 'https://coupon.mediba.jp',
  },
}

type Env = keyof typeof environment

export const config = environment[process.env.REACT_APP_ENV as Env]
