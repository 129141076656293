import { FC } from 'react'
import ReactBarcode from 'react-barcode'

type Props = {
  value: string
}

export const Barcode: FC<Props> = (props) => {
  const { value } = props

  // @see: https://www.npmjs.com/package/react-barcode
  // valueに数字以外が混ざる場合バーコードが肥大化するので注意
  return <ReactBarcode format='CODE128' value={value} width={1} height={100} fontSize={12} />
}
