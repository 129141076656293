import { LinkNode } from '@lexical/link'
import { LexicalComposer } from '@lexical/react/LexicalComposer'
import { ContentEditable } from '@lexical/react/LexicalContentEditable'
import LexicalErrorBoundary from '@lexical/react/LexicalErrorBoundary'
import { HistoryPlugin } from '@lexical/react/LexicalHistoryPlugin'
import { LinkPlugin } from '@lexical/react/LexicalLinkPlugin'
import { RichTextPlugin } from '@lexical/react/LexicalRichTextPlugin'
import { Box, ClassNameMap } from '@mui/material'
import { FC } from 'react'
import { useStyles } from './styles'
import { useStyles as useThemeStyles } from './theme'

type Props = {
  editorState: string
  theme: ClassNameMap
  namespace: string
}

export const RichText: FC<Props> = (props) => {
  const { editorState, namespace } = props
  const themeClasses = useThemeStyles()
  const classes = useStyles()

  const editorNodes = [LinkNode]
  const config: Parameters<typeof LexicalComposer>['0']['initialConfig'] = {
    namespace,
    onError: (e, editor) => console.log(`e => ${e} editor => ${editor}`),
    nodes: editorNodes,
    theme: themeClasses,
    editorState,
    editable: false,
  }

  return (
    <Box>
      <LexicalComposer initialConfig={config}>
        <RichTextPlugin
          contentEditable={<ContentEditable className={classes.editableContainer} />}
          placeholder={<></>}
          ErrorBoundary={LexicalErrorBoundary}
        />
        <LinkPlugin />
        <HistoryPlugin />
      </LexicalComposer>
    </Box>
  )
}
