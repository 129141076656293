import { Box, Theme, useMediaQuery } from '@mui/material'
import { FC } from 'react'
import { Autoplay, Pagination } from 'swiper'
import { SwiperSlide, Swiper } from 'swiper/react'

import 'swiper/css'
import 'swiper/css/pagination'
import './swiperBullet.css'

type Props = {
  images: string[]
}

export const ImageSwiper: FC<Props> = (props) => {
  const { images } = props
  const matches = useMediaQuery((theme: Theme) => theme.breakpoints.down('md'))
  const height = matches ? 175.6 : 360

  return (
    <Box height={`${height + 20}px`}>
      <Swiper
        pagination
        modules={[Pagination, Autoplay]}
        centeredSlides
        style={{ width: '100%', height: `${height}px` }}
        slidesPerView='auto'
        spaceBetween={16}
        autoplay={{
          delay: 3500,
          disableOnInteraction: false,
        }}
      >
        {images.map((image, index) => (
          <SwiperSlide
            key={`image ${index}`}
            style={{
              height: '100%',
              width: 'fit-content',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <img
              src={image}
              style={{ objectFit: 'contain', height, width: matches ? '310px' : '638px' }}
            />
          </SwiperSlide>
        ))}
      </Swiper>
    </Box>
  )
}
