import { createStyles, makeStyles } from '@mui/styles'

export const useStyles = makeStyles(
  createStyles({
    editableContainer: {
      outline: 'none',
      boxShadow: 'none',
    },
  }),
)
