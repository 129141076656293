import dayjs from 'dayjs'
import ja from 'dayjs/locale/ja'
import timezone from 'dayjs/plugin/timezone'
import utc from 'dayjs/plugin/utc'
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.locale(ja)

export type DateTimeFormat = 'YYYY/MM/DD (ddd) HH:mm'
export const convertDateTime = (dateTime: string, format: DateTimeFormat) => {
  const dt = dayjs(dateTime)
  return dt.tz('Asia/Tokyo').format(format)
}
