import { Box, Typography } from '@mui/material'
import { FC } from 'react'
import { isURL } from '../../utils/url'

type Props = {
  clientLogo: string
}

export const Header: FC<Props> = (props) => {
  const { clientLogo } = props
  const isImg = isURL(clientLogo)

  return (
    <Box
      width='100%'
      height='44px'
      bgcolor='#fff'
      display='flex'
      justifyContent='center'
      alignItems='center'
    >
      {isImg ? (
        <img src={clientLogo} height='24px' />
      ) : (
        <Typography variant='h4' component='h1' fontSize='16px'>
          {clientLogo}
        </Typography>
      )}
    </Box>
  )
}
