import { Stack, Typography } from '@mui/material'
import { FC } from 'react'
import { CheckIcon } from './CheckIcon'

//  1:利用済み, 2:期間外, 3:停止中
const messages = {
  1: 'こちらのクーポンは利用済みです',
  2: 'こちらのクーポンは利用期間外となります',
  3: 'こちらのクーポンは利用を終了させていただきました',
} as const

type Props = {
  mode: keyof typeof messages
}

export const AttentionLabel: FC<Props> = (props) => {
  const { mode } = props
  return (
    <Stack
      display='flex'
      alignItems='center'
      direction='row'
      width='100%'
      justifyContent='center'
      bgcolor='#FFD25E'
      boxSizing='border-box'
      spacing='8px'
      py={mode === 1 ? '8px' : '16px'}
      px='16px'
    >
      {mode === 1 && <CheckIcon />}
      <Typography
        variant='body1'
        fontWeight='bold'
        fontSize='16px'
        lineHeight='30px'
        textAlign='left'
      >
        {messages[mode]}
      </Typography>
    </Stack>
  )
}
